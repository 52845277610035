import type { FormInstance } from 'antd';
import { Checkbox, InputNumber, message } from 'antd';
import Compressor from 'compressorjs';
import type { BaseData } from 'egenie-common';
import { request, singleUpload } from 'egenie-common';
import { action, computed, observable, toJS } from 'mobx';
import { api } from '../../utils';
import styles from './index.less';
import type { IAliOssPolicy, IGoodsItem, IOption, IReason, UploadList } from './types';
import React from 'react';

export default class Store {
  constructor({ parent }) {
    this.parent = parent;
    this.initPage();
  }

  @observable public fullModalVisible = false;

  @observable public step = 0;

  @observable public parent;

  @observable public confirmLoading = false;

  @observable public mallSaleOrderId: number; // 选款订单id

  @observable public result: 'success' | 'fail';

  @observable public failReason = '';

  @observable public pageLoading = false; // 页面加载loading

  @observable public tableLoading = false;

  @observable public whetherSupport = false; // 是否有商品支持售后

  // stepOne
  @observable public serveType: 1 | 2 = 1; // 1：常规售后；2：特殊申请

  // stepTwo
  @observable public serveGoodsList: IGoodsItem[] = []; // 售后商品展示列表

  @observable public deliveredList: IGoodsItem[]; // 已发货可退商品

  @observable public toDeliverList: IGoodsItem[]; // 未发货可退商品

  @observable public deliverType = 'delivered'; // 已发货delivered 未发货toDeliver

  @observable public reasonCode = null;

  @computed
  public get choosedNum() {
    if (!this.serveGoodsList) {
      return 0;
    }
    const num = this.serveGoodsList.reduce((pre, cur) => {
      const skuCount = cur.skuVoList.reduce((skuPre, skuCur) => {
        if (skuCur.checked) {
          return skuPre + Number(skuCur.applyNum);
        } else {
          return skuPre;
        }
      }, 0);
      return pre + skuCount;
    }, 0);
    return num;
  }

  // 预计总金额
  @computed
  public get returnAmount() {
    if (!this.serveGoodsList) {
      return '0.00';
    }
    const amount = this.serveGoodsList.reduce((pre, cur) => {
      const skuCount = cur.skuVoList.reduce((skuPre, skuCur) => {
        if (skuCur.checked) {
          return skuPre + Number(skuCur.applyNum) * skuCur.skuPlatformWholePrice;
        } else {
          return skuPre;
        }
      }, 0);
      return pre + skuCount;
    }, 0);
    return amount.toFixed(2);
  }

  // stepThree
  @observable public ref: FormInstance;

  @observable public reasonOptions: IOption[] = [];

  @observable public aliOssPolicy: IAliOssPolicy; // 阿里云临时token

  @observable public uploadPath = ''; // 上传路径

  @observable public uploadedList: UploadList[] = []; // 已上传文件列表

  @observable public selectedParams; // 步骤3已选择的数据

  @computed
  public get uploadDisable() {
    return this.uploadedList.length >= 5;
  }

  public initPage = (): void => {
    this.queryReason();

    // this.queryAliPolicy();
  };

  // 改变fullModal显隐
  @action public changeVisible = (visible: boolean, mallSaleOrderId?: number) => {
    this.fullModalVisible = visible;
    if (!visible) {
      Object.assign(this, {
        step: 0,
        serveGoodsList: [],
        uploadedList: [],
        selectedParams: null,
        deliverType: 'delivered',
        mallSaleOrderId: null,
      });
      this.ref?.resetFields();
      if (this.parent?.applyReturnCancel) {
        this.parent.applyReturnCancel();
      }
    }
    if (visible && mallSaleOrderId) {
      Object.assign(this, { mallSaleOrderId }); // 如果重选款采购订单进入
    }

    this.queryNormalAfterSale();
  };

  @observable public queryNormalAfterSale = async() => {
    const res = await request<BaseData>({ url: api.queryNormalAfterSale });
    this.whetherSupport = Boolean(res.data);
    this.serveType = res.data ? 1 : 2;
  };

  // step状态改变, 1前进；-1后退
  @action public stepGo = (go: 1 | -1) => {
    if (this.step === 1 && go === 1) {
      // 第二步往前时校验sku的数量
      const skuList = this.getSkuList();
      if (skuList?.length === 0) {
        message.warning('请选择售后商品');
        return;
      }
    }
    if (this.step === 2) { // 记录步骤2的内容
      this.selectedParams = this.ref?.getFieldsValue();
    }
    this.step += go;
    if (this.step === 1) {
      this.rowSelection.selectedRowKeys = [];
      this.queryGoodsList();
    }
    if (this.step === 2) {
      setTimeout(() => {
        if (this.selectedParams) { // 重置步骤3数据
          this.ref?.setFieldsValue(this.selectedParams);
        }
      }, 0);
    }
  };

  // stepOne
  @action public onServeTypeChange = (serveType: 1 | 2) => {
    this.serveType = serveType;

    // 售后类型改变后清空之前选择的售后商品
    this.serveGoodsList = [];
  };

  // stepTwo

  @observable public tableProps = {
    rowKey: 'goodsId',
    columns: [
      {
        key: 'goodsName',
        title: '商品',
        width: 300,
        render: (text, record) => {
          return (
            <div style={{ display: 'flex' }}>
              <img
                src={record.mainUrl}
                style={{
                  width: '30px',
                  height: '30px',
                  marginRight: '8px',
                }}
              />
              <div className={styles.goodsName}>
                {record.goodsName}
              </div>
            </div>
          );
        },
      },
      {
        key: 'sellerOuterNo',
        title: '货号',
        width: 200,

        render: (text, record) => {
          return (
            <div>
              {record.sellerOuterNo}
            </div>
          );
        },
      },
      {
        key: 'goodsSpec',
        title: '规格',
        width: 300,
        render: (text, record) => {
          return (
            <div>
              {
                record.skuVoList.map((el) => {
                  return (
                    <div
                      className={styles.skuContent}
                      key={el.skuId}
                    >
                      <Checkbox
                        checked={el.checked}
                        onChange={(e) => this.chooseSku(record.goodsId, el.skuId, e.target.checked)}
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '8px',
                        }}
                      />
                      <span>
                        {el.goodsSpec}
                      </span>
                    </div>
                  );
                })
              }
            </div>
          );
        },
      },
      {
        key: 'returnNum',
        title: '可退数量',
        width: 100,
        render: (text, record) => {
          return (
            <div>
              {
                record.skuVoList.map((el) => {
                  return (
                    <div
                      className={styles.skuContent}
                      key={el.skuId}
                    >
                      <span>
                        {el.returnNum}
                      </span>
                    </div>
                  );
                })
              }
            </div>
          );
        },
      },
      {
        key: 'applyNum',
        title: '申请数量',

        render: (text, record) => {
          return (
            <div>
              {
                record.skuVoList.map((el) => {
                  return (
                    <div
                      className={styles.skuContent}
                      key={el.skuId}
                    >
                      <InputNumber
                        max={el.returnNum}
                        min={1}
                        onChange={(value) => this.changeSkuNum(record.goodsId, el.skuId, value)}
                        style={{ width: '150px' }}

                        value={el.applyNum}
                      />
                    </div>
                  );
                })
              }
            </div>
          );
        },
      },
    ],
  };

  @observable public rowSelection = {
    selectedRowKeys: [],
    onSelect: (record, selected) => {
      this.handleSelectedRows(record.goodsId, selected);
      this.handleProductList(this.rowSelection.selectedRowKeys);
    },
    onSelectAll: (selected) => {
      console.log('onSelectAll', selected);
      let list = [];
      if (selected) {
        list = this.serveGoodsList.map((el) => {
          return el.goodsId;
        });
      }
      this.rowSelection.selectedRowKeys = toJS(list);
      this.handleProductList(list);
    },
  };

  // 查询售后商品
  public queryGoodsList = async() => {
    this.tableLoading = true;
    try {
      const res = await request<BaseData<any>>({
        url: api.queryReturnGoods,
        method: 'post',
        data: {
          afterSaleType: this.serveType,
          mallSaleOrderId: this.mallSaleOrderId,
        },
      });
      this.deliveredList = res.data.deliveredList?.map(((el) => {
        el.skuVoList = el.skuVoList.map((item) => {
          return {
            ...item,
            applyNum: item.returnNum,
          };
        });
        return el;
      }));
      this.toDeliverList = res.data.toDeliverList?.map(((el) => {
        el.skuVoList = el.skuVoList.map((item) => {
          return {
            ...item,
            applyNum: item.returnNum,
          };
        });
        return el;
      }));

      this.serveGoodsList = this.deliveredList?.length ? this.deliveredList : this.toDeliverList;
      this.deliverType = this.deliveredList?.length ? 'delivered' : 'toDeliver';
    } catch (e) {
      console.log('查询售后商品错误', e);
    } finally {
      this.tableLoading = false;
    }
  };

  // 选择发货数据
  @observable public changeDeliveryType = (type) => {
    this.deliverType = type;
    this.serveGoodsList = this[`${type}List`] || [];
    this.rowSelection.selectedRowKeys = [];
    this.handleProductList([]);
  };

  // 表格数据勾选和计算
  @observable public handleProductList = (selectedIds: number[]): void => {
    const list = this.serveGoodsList;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const choosedId = selectedIds.find((el) => item.goodsId === el);
      for (let j = 0; j < item.skuVoList.length; j++) {
        const sku = item.skuVoList[j];
        sku.checked = Boolean(choosedId);
      }
    }
    this.serveGoodsList = toJS(list);
  };

  // 子表勾选
  @observable public chooseSku = (goodsId: number, skuId: number, checked: boolean): void => {
    const list = this.serveGoodsList;
    const productIndex = list.findIndex((el) => el.goodsId === goodsId);
    const skuList = list[productIndex].skuVoList;
    let skuCheckNum = 0;
    for (let i = 0; i < skuList.length; i++) {
      const element = skuList[i];
      if (element.skuId === skuId) {
        element.checked = checked;
      }
      if (element.checked) {
        skuCheckNum += 1;
      }
    }
    this.handleSelectedRows(goodsId, skuCheckNum === skuList.length);

    this.serveGoodsList = toJS(list);
  };

  // 处理选中行
  @observable public handleSelectedRows = (goodsId, selected) => {
    let selectedRowKeys = this.rowSelection.selectedRowKeys;
    if (selected) {
      selectedRowKeys.push(goodsId);
    } else {
      selectedRowKeys = selectedRowKeys.filter((el) => el !== goodsId);
    }
    this.rowSelection.selectedRowKeys = toJS(selectedRowKeys);
  };

  // 修改sku数量
  @action public changeSkuNum = (goodsId: number, skuId: number, value: string | number) => {
    const goodsItem = this.serveGoodsList.find((el) => el.goodsId === goodsId);
    const skuItem = goodsItem.skuVoList.find((el) => el.skuId === skuId);
    skuItem.applyNum = value ?? 1;
    this.serveGoodsList = toJS(this.serveGoodsList);
  };

  // stepThree
  // 设置ref
  @action public setRef = (ref: FormInstance) => {
    this.ref = ref;
  };

  // 请求退货原因
  @action public queryReason = async(): Promise<void> => {
    const res: BaseData<IReason[]> = await request({ url: api.queryReason });
    this.reasonOptions = res?.data.map((el) => {
      return {
        label: el.name,
        value: el.code,
      };
    });
  };

  // 请求阿里云上传临时token
  @action private queryAliPolicy = async(): Promise<void> => {
    const res: BaseData<IAliOssPolicy> = await request({
      url: api.queryPolicy,
      method: 'post',
      data: { type: 1 },
    });
    this.aliOssPolicy = res.data;
  };

  // 上传阿里云
  public beforeUpload = (file: File) => {
    if (this.checkPicFormat(file)) {
      this.uploadFn(file);
    }
    return false;
  };

  // 图片格式、大小校验
  private checkPicFormat = (file: File): boolean => {
    const mimeType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/heic';
    if (!mimeType) {
      message.error('请上传jpg或png格式图片！');
      return false;
    }
    if (file.size > 30 * 1024 * 1024) {
      message.error('图片文件大小超过限制');
      return false;
    }
    return true;
  };

  // 上传图片
  @observable public uploadFn = (file: File): void => {
    // const {
    //   region,
    //   accessKeyId,
    //   accessKeySecret,
    //   bucketName,
    //   token,
    //   dir,
    //   host
    // } = this.aliOssPolicy;
    new Compressor(file, {
      quality: 0.6,
      success: async(result) => {
        try {
          this.uploadedList.push({
            imageUrl: '',
            status: 'loading',
            progress: 0,
          });

          // const client = new OSS({
          //   region,
          //   accessKeyId,
          //   accessKeySecret,
          //   bucket: bucketName,
          //   stsToken: token,
          // });
          // const name = `${Date.now()}_${file.name.replace(/,|，| |/g, '')}`;
          // const uploadRes = await client.multipartUpload(`${dir}/${name}`, result, {
          //   progress: action((p, checkpoint) => {
          //     this.uploadedList[this.uploadedList.length - 1].progress = p;
          //   }),
          //   parallel: 4,
          //   partSize: 1024 * 1024,
          //   mime: result.type,
          // });
          const url = await singleUpload({ obsConfigId: 27 }, file);
          if (url) {
            this.uploadedList[this.uploadedList.length - 1].imageUrl = url;
            this.uploadedList[this.uploadedList.length - 1].status = 'finish';
            this.ref?.validateFields(['voucher']);
          } else {
            this.uploadedList.pop();
            message.error('上传失败！');
          }
        } catch (err) {
          this.uploadedList.pop();
          message.error('上传失败！');
        }
      },
    });
  };

  // 删除上传的图片链接
  @observable public deleteImageUrl = (imageUrl: string) => {
    this.uploadedList = this.uploadedList.filter((el) => el.imageUrl !== imageUrl);
  };

  @action public changeReason = (val: number): void => {
    this.reasonCode = val;
  };

  // 校验图片数量
  public validatePicNum = (): Promise<string> => {
    if (this.reasonCode === 9 || this.uploadedList.length > 0) {
      return Promise.resolve('');
    }
    return Promise.reject('请上传退货凭证');
  };

  // 获取要提交的sku
  private getSkuList = (): unknown[] => {
    const returnGoodsParams = [];
    for (const goods of this.serveGoodsList) {
      goods.skuVoList.forEach((el) => {
        if (el.checked) {
          returnGoodsParams.push({
            goodsId: goods.goodsId,
            num: el.applyNum,
            skuId: el.skuId,
          });
        }
      });
    }
    return returnGoodsParams;
  };

  // 提交
  public submit = (): void => {
    this.ref?.validateFields()
      .then((res) => {
        const values = this.ref?.getFieldsValue();
        const returnGoodsParams = this.getSkuList();

        const data = {
          ...values,
          returnGoodsParams,
          voucher: this.uploadedList.map((el) => el.imageUrl)
            ?.join(','),
          afterSaleType: this.serveType,
          delivered: this.deliverType === 'delivered' ? 1 : 0,
        };

        if (this.mallSaleOrderId) { // 如果是选款采购订单详情申请加上选款订单id
          Object.assign(data, { mallSaleOrderId: this.mallSaleOrderId });
        }

        this.confirmLoading = true;

        // 有sku信息
        request({
          url: api.submitReturnApply,
          method: 'post',
          data,
        })
          .then((res: BaseData<string>) => {
            if (res.status === 'Successful') {
              this.result = 'success';
            }
            if (this.parent?.onApplySuccess) {
              this.parent?.onApplySuccess();
            }

            this.step = 0;
            this.fullModalVisible = false;
            if (this.parent?.pageName && this.parent.pageName === 'afterSale') {
              this.parent?.programm?.handleSearch();
            } else {
              this.parent?.queryOrder();
              window.top.egenie.openTab('/egenie-ts-vogue/afterSaleOrder/index', 757, '衫海精售后单');
            }
          })
          .catch((res) => {
            if (res.status !== 'Successful') {
              this.failReason = res.data?.info || '提交失败，请联系管理员或重试';
              this.result = 'fail';
            }
            this.stepGo(1);
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
  };

  // result
  public jumpToAfterSaleOrder = (): void => {
    window.top.egenie.openTab('/egenie-ts-vogue/afterSaleOrder/index', 754, '售后管理');
  };
}
